import SearchInput from '~/themes/base/components/shop/SearchInput/SearchInput.js'

export default {
  extends: SearchInput,
  watch: {
    query(value) {
      this.query = value.replace(/[^a-zA-Z0-9\s-_\.À-ž]/g, '');
    },
  },
}
