import CatalogBanner from '~/themes/base/components/content/CatalogBanner'
import eventDefinitions from '@/events/eventDefinitions'

export default {
  extends: CatalogBanner,
  computed: {
    promotionData() {
      const { item_id, item_name, title } = this.banner
      return {
        id: item_id ?? title,
        name: item_name ?? title,
      }
    },
  },
  mounted() {
    this.$eventBus.$emit(eventDefinitions.PROMOTION.IMPRESSION, { promotion: this.promotionData })
  },
  methods: {
    clicked() {
      this.$eventBus.$emit(eventDefinitions.PROMOTION.CLICK, { promotion: this.promotionData })
    },
  },
}
